/* Services.css */

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px; /* Add padding to give space for header */
}

.services-header-container {
  background: linear-gradient(
    120deg,
    #2980b9,
    #6dd5fa
  ); /* Gradient background */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Shadow */
  overflow: hidden;
  margin-bottom: 50px;
}

.services-header-content {
  padding: 50px 20px; /* Padding */
  text-align: center;
}

.services-header {
  font-size: 48px;
  color: #fff; /* Text color */
  margin-bottom: 20px;
}

.services-subheader {
  font-size: 24px;
  color: #fff; /* Text color */
}

.service-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; /* Add space between service rows */
}

/* Styles for alternate service rows */
.service-row.alternate {
  flex-direction: row-reverse; /* Reverse order for alternate rows */
}

.service-row.alternate,
.service-content {
  margin-right: 20px; /* Reverse order for alternate rows */
}

.service-image {
  flex: 1;
  margin-right: 20px; /* Add space between image and content */
  height: 100%;
}

.service-image img {
  max-width: 100%; /* Ensure images don't exceed container width */
  height: 100%; /* Maintain aspect ratio */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Hover effect for service images */
.service-image img:hover {
  transform: scale(1.1); /* Scale up the image on hover */
}

.service-content ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
}

/* Styles for list items */
.service-content {
  margin-bottom: 10px; /* Add space between list items */
  opacity: 0; /* Initially set opacity to 0 for animation */
  transform: translateX(-20px); /* Initially translate items to the left */
  animation: slideIn 0.5s forwards; /* Apply slideIn animation */
}

/* Styles for paragraphs within list items */
.service-content ul li p {
  margin: 5px 0; /* Add space above and below paragraphs */
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px); /* Start position */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* End position */
  }
}

.service-content {
  text-align: left;
  margin-top: 20px;
  flex: 1;
  padding: 20px; /* Add padding to create space around the content */
  background-color: #f9f9f9; /* Background color for the content */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effect for transform and box-shadow */
  cursor: pointer; /* Change cursor to pointer on hover */
  height: 100%;
}

/* Hover effect for service content */
.service-content:hover {
  transform: translateY(-5px); /* Move content upward slightly on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

/* Styles for service content headers */
.service-content h2 {
  font-size: 28px; /* Increase font size for content headers */
  color: #333; /* Text color for content headers */
  margin-bottom: 15px; /* Add more space below the content headers */
  text-transform: uppercase; /* Convert text to uppercase for a catchy look */
  letter-spacing: 2px; /* Add letter spacing for emphasis */
  font-weight: bold; /* Apply bold font weight for emphasis */
  line-height: 1.3; /* Adjust line height for better alignment */
}

/* Styles for service content paragraphs */
.service-content p,
li {
  font-size: 16px; /* Adjust font size for content paragraphs */
  color: #666; /* Text color for content paragraphs */
  margin-bottom: 10px; /* Add space below paragraphs */
}

@media (max-width: 768px) {
  .service-row {
    flex-direction: column; /* Change to column layout on smaller screens */
    align-items: center; /* Center align items */
  }

  .service-content,
  .service-image {
    width: 100%; /* Make content and image take full width */
    margin-bottom: 20px; /* Add some spacing between items */
    text-align: center; /* Center align text */
    height: auto; /* Auto height for mobile */
  }

  .service-content h2 {
    font-size: 24px; /* Adjust font size for headings */
  }

  .service-content p {
    font-size: 16px; /* Adjust font size for paragraphs */
  }
}

.read-more-button {
  background-color: black; /* Blue background color */
  color: white; /* White text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding around the button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
}

.modal-title {
  font-size: 26px;
  font-weight: bolder;
  color: #333; /* Adjust color as needed */
}

.modal-content p {
  font-size: 16px;
  color: #555; /* Adjust color as needed */
  opacity: 1; /* Initially hide the content */
  transition: opacity 0.5s ease; /* Apply transition effect */
}
