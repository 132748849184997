/* Header.css */
.nav-link {
  color: #fff !important;
  transition: color 0.3s ease-in-out, cursor 0.3s ease-in-out; /* Smooth color transition */
  cursor: pointer; /* Change cursor to pointer */
}

.nav-link:hover,
.nav-link:focus {
  /* Added :focus for keyboard navigation accessibility */
  color: #17a2b8 !important; /* Change color on hover */
}

.nav-link.active {
  color: #17a2b8 !important; /* Active menu item color */
}

/* Mobile menu styles */
.navbar-collapse {
  background-color: #343a40;
}

.nav-link.active {
  color: #fff !important; /* Active menu item color */
}

.nav-link.active:hover,
.nav-link.active:focus {
  /* Added :focus for keyboard navigation accessibility */
  color: #fff !important; /* Active menu item color on hover */
}

.navbar-toggler-icon {
  background-color: #fff; /* Hamburger menu color */
}

.navbar-toggler {
  border-color: #fff; /* Hamburger menu border color */
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 18px !important; /* Adjust font size for mobile view */
    padding: 8px 0 !important; /* Adjust padding for mobile view */
  }
}

.navbar-brand {
  margin-right: auto; /* Align the logo to the left */
}

.navbar-logo {
  width: 270px; /* Adjust the width of the logo */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
  margin-right: 10px; /* Add some space between the logo and the navigation menus */
}
