/* Privacy.css */
.privacy {
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.privacy h1,
.privacy h2,
.privacy h3,
.privacy h4,
.privacy h5,
.privacy h6 {
  color: #333;
}

.privacy p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.privacy a {
  color: #007bff;
  text-decoration: none;
}

.privacy a:hover {
  text-decoration: underline;
}

.privacy ul,
.privacy ol {
  margin-bottom: 20px;
}

.privacy ul li,
.privacy ol li {
  margin-bottom: 10px;
}

/* Add more styles as needed */
