/* FreeTrialModal.css */
.modal-content {
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.modal-header {
  border-bottom: none; /* Remove border bottom from header */
}

.modal-footer {
  border-top: none; /* Remove border top from footer */
}

.form-control {
  background-color: #e9ecef; /* Lighter background for form inputs */
  border: none; /* Remove border */
}

.get-started-button {
  width: 200px; /* Set width of button */
  border-radius: 5px; /* Add border-radius */
  font-size: 16px; /* Increase font size */
  margin-top: 20px; /* Add margin top */
}

.modal-title {
  font-size: 24px; /* Increase font size */
  font-weight: bold; /* Make font bold */
  margin-bottom: 20px; /* Add margin bottom for spacing */
}
