/* Home.css */

.world-container {
  flex: 1; /* Take up remaining space */
  margin-left: 10%;
}

.tagline-container {
  flex: 1; /* Take up remaining space */
  text-align: left; /* Center align text */
  margin-right: 10%;
}

.tagline {
  font-size: 70px;
  font-weight: bold;
  color: #333;
}

.tagline-line {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

/* Typing animation */
.tagline-animation::before {
  content: attr(data-text);
  animation: typing 4s steps(40) forwards;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Adjust font size for mobile */
@media screen and (max-width: 768px) {
  .home-container {
    flex-direction: column;
    align-items: center;
  }

  .world-container {
    width: 100%;
    margin-left: 20%;
  }

  .tagline-container {
    width: 100%;
    margin-right: 0;
    padding-left: 15%;
  }

  .tagline {
    margin-top: 10%;
    font-size: 27px; /* Decrease font size for smaller screens */
  }

  #world {
    margin-top: auto;
    display: flex;
    justify-content: center; /* Add space on top for better alignment */
  }
}

.free-trial-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust margin as needed */
}

/* Home.css */
.free-trial-button {
  background-color: black; /* Button color */
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.free-trial-button:hover {
  background-color: white; /* Button color on hover */
  color: black;
}

.icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the position of the icon */
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out; /* Add transition animation */
}

/* Add space between text and icon */
.free-trial-button .text {
  margin-right: 15px; /* Adjust the space */
}

.free-trial-button:hover .icon {
  transform: translateY(-50%) translateX(5px); /* Move the icon slightly on hover */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate {
  animation: pulse 1s infinite; /* Apply the pulse animation to the button */
}
