/* Contact Us component styles */
.contact-container {
  margin-top: 50px;
  padding-bottom: 50px; /* Add padding below */
}

.contact-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  min-height: 300px; /* Set minimum height for the form column */
}

.contact-form h2 {
  margin-bottom: 20px;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.contact-form button {
  background-color: #000000; /* Update background color to black */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #333333; /* Darker black color on hover */
}

/* Address section styling */
.address-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  min-height: 300px; /* Set minimum height for the address column */
}

.address-section h2 {
  margin-bottom: 20px;
}

/* Google Map section styling */
.google-map {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  min-height: 300px; /* Set minimum height for the map column */
}

.google-map h2 {
  margin-bottom: 20px;
}

.google-map iframe {
  border: 1px solid #ced4da;
  border-radius: 10px;
  height: 100%; /* Set the iframe height to fill the column */
}

.contact-fade-in {
  opacity: 0;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contact-fade-in {
  animation: fadeIn 1s ease forwards;
}

/* Styles for headers */
.contact-form h2,
.address-section h2,
.google-map h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif; /* Specify the desired font family */
}

.contact-form h2 {
  color: #007bff; /* Blue color for Contact Form header */
}

.address-section h2 {
  color: #28a745; /* Green color for Company Address header */
}

.google-map h2 {
  color: #dc3545; /* Red color for Google Map header */
}
