/* Footer.css */
.footer {
  background-color: rgb(33, 37, 41);
  color: #fff;
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-column {
  flex: 1;
  padding: 0 15px;
  text-align: center;
}

.gdpr-logo {
  max-width: 50%;
}

.company-logo {
  max-width: 100%;
}

.footer-menu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.footer-menu li {
  margin-bottom: 10px;
}

.footer-menu li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.footer-menu li a:hover {
  color: #ffd700; /* Change to desired hover color */
}

.footer-menu li a:before {
  content: ">";
  margin-right: 5px;
}

.footer-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.legal-links {
  display: flex;
}

.legal-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: #ffd700; /* Change to desired hover color */
}

.social-icons a {
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s ease;
  color: white;
}

.social-icons a:hover {
  transition: color 0.3s ease;
}

.social-icons a[href*="facebook.com"]:hover {
  color: #1877f2; /* Facebook blue */
}

.social-icons a[href*="twitter.com"]:hover {
  color: #1da1f2; /* Twitter blue */
}

.social-icons a[href*="instagram.com"]:hover {
  color: #c13584; /* Instagram pink */
}

.social-icons a[href*="linkedin.com"]:hover {
  color: #0077b5; /* LinkedIn blue */
}
