/* Loader.css */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
}

.dot-1,
.dot-2,
.dot-3 {
  animation: dot-animation 1.5s infinite;
  opacity: 0;
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
